import React, { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import cognismilesLogo from "../assets/cognismiles-logo.png"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState({ confirmPassword: "", role: "",captcha: "" });
  
 const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();

  const roleDetails = [
    { roleID: 1, roleName: "ADMIN" },
    { roleID: 2, roleName: "DOCTOR" },
    { roleID: 3, roleName: "PATIENT" },
  ];

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.replace(" ", ""),
    }));
    if (name === "confirmPassword" && formData.password !== value) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: "Passwords do not match",
      }));
    } else if (name === "confirmPassword") {
      setError((prevError) => ({ ...prevError, confirmPassword: "" }));
    }
  };

  const handleRegistration = async () => {
    if (formData.password !== formData.confirmPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match",
      }));
      return;
    }

    const selectedRole = roleDetails.find(
      (role) => role.roleName.toLowerCase() === formData.role.toLowerCase()
    );
    if (!selectedRole) {
      setError((prev) => ({ ...prev, role: "Invalid role selected" }));
      return;
    }

    if (!executeRecaptcha) {
      console.error("Execute reCAPTCHA function is not available");
      setError((prevError) => ({
        ...prevError,
        captcha: "reCAPTCHA is not available. Please try again later",
      }));
      return;
    }

    
    try {
      const token = await executeRecaptcha("register");
      console.log("reCAPTCHA token:", token);
      if (!token) {
        setError((prevError) => ({
          ...prevError,
          captcha: "No token found for recaptcha",
        }));
        //setError("No token found for recaptcha");
        return;
      }
      const payload = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        postal_code: formData.postalCode,
        phone_number: `+91-${formData.phoneNumber}`,
        email_id: formData.email.trim(),
        password: formData.password,
        role_id: selectedRole.roleID,
      };
      const response = await axios.post(`${process.env.REACT_APP_NEW_API_URL}/register`,payload
      );
      if (response?.status === 200) {
        toast.success("Registered Successfully");
        navigate("/login");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          postalCode: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
          role: "",
        });
      }
    } catch (error) {
      console.error(
        "Error during registration:",
        error?.response?.data?.data?.message
      );
      const message =
        error?.response?.data?.data?.message || "Registration failed";
      toast.error(message);
    }
  };

  return (
    <div className="bg-[#F9F8F8] min-h-screen py-10 font-poppins">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6 space-y-8">
          {/* <img
            src="https://media.licdn.com/dms/image/v2/D560BAQHxTaftB0KSSg/company-logo_200_200/company-logo_200_200/0/1719257947698/altumindinc_logo?e=1735776000&v=beta&t=5tC7kH9vgkJfzIIxo0iaC9xXRKluZqSehTlRw_bOnRc"
            alt="altumind-logo"
            className="w-24 h-auto mx-auto"
          /> */}
           <img className="w-[100px] h-auto mx-auto" src={cognismilesLogo} alt="logo" />
          <p className="text-xl font-semibold leading-tight tracking-tight text-gray-800 md:text-2xl ">
            Create your Account
          </p>
          <form
            className="space-y-4 md:space-y-6"
            onSubmit={(e) => {
              e?.preventDefault();
              handleRegistration();
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^a-zA-Z]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      [e.target.name]: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="John"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^a-zA-Z]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      [e.target.name]: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Doe"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="postalCode"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Postal Code <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      postalCode: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="123456"
                  minLength={6}
                  maxLength={6}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({
                      ...prevData,
                      phoneNumber: value,
                    }));
                  }}
                  className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="9876543210"
                  minLength={10}
                  maxLength={10}
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Your email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => {
                  const value = e.target.value.trimStart(); // Trim leading spaces only
                  setFormData((prevData) => ({
                    ...prevData,
                    email: value,
                  }));
                }}
                className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="name@company.com"
                required
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Password <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={(e) =>
                      handleChange("password", e.target.value.replace(" ", ""))
                    }
                    className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="••••••••"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    {showPassword ? (
                      <svg
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                         aria-label="Hide Password"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                        />
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                         aria-label="Show password"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Confirm password <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={(e) =>
                      handleChange("confirmPassword", e.target.value)
                    }
                    className="w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="••••••••"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    {showConfirmPassword ? (
                      <svg
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                         aria-label="Hide password"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                        />
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                         aria-label="Show password"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                {error.confirmPassword && (
                  <p className="mt-2 text-sm text-red-600">
                    {error.confirmPassword}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="role"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Select Role <span className="text-red-500">*</span>
              </label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={(e) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    role: e.target.value,
                  }));
                  setError((prevError) => ({ ...prevError, role: "" }));
                }}
                className="w-full p-3 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                required
              >
                <option value="" disabled>
                  Select a role
                </option>
                {roleDetails.map((role) => (
                  <option key={role.roleID} value={role.roleName.toLowerCase()}>
                    {role.roleName}
                  </option>
                ))}
              </select>
              {error.role && (
                <p className="mt-2 text-sm text-red-600">{error.role}</p>
              )}
            </div>
            <div className="flex flex-col items-center space-y-4">
              <button
                type="submit"
                className="w-full sm:w-auto px-8 py-2.5 text-white bg-[#4838C8] hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-[#4838C8]-300 font-medium rounded-lg text-sm text-center"
              >
                Create an account
              </button>  
              {error.captcha && (
                <p className="mt-2 text-sm text-red-600">{error.captcha}</p>
              )}

              <p className="text-sm text-gray-700">
                Already have an account?{" "}
                <a
                  href="/login"
                  className="font-medium text-[#4838C8] hover:underline"
                >
                  Login here
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
