import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import cognismilesLogo from "../assets/cognismiles-logo.png";
import { UserContext } from "../Context/UserContext";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setUsers } = useContext(UserContext);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!executeRecaptcha) {
      console.error("Execute reCAPTCHA function is not available");
      setErrorMessage("reCAPTCHA is not available. Please try again later.");
      return;
    }
    try {
      // const token = await executeRecaptcha("login");
      // console.log("reCAPTCHA token:", token);
      // if (!token) {
      //   setErrorMessage("No token found for recaptcha");
      //   return;
      // }

      const captchaToken = await executeRecaptcha("login");
      console.log("reCAPTCHA token:", captchaToken);
      if (!captchaToken) {
        setErrorMessage(
          "Failed to generate reCAPTCHA token. Please try again."
        );
        return;
      }

      const tokenPayload = {
        token: captchaToken,
      };
      const captchaUrl = `${process.env.REACT_APP_NEW_API_URL}/verify-recaptcha`;
      const captchaResponse = await axios.post(captchaUrl, tokenPayload);
      // if (captchaResponse?.data?.success !== true) {
      //   setErrorMessage("reCAPTCHA verification failed. Please try again.");
      //   return;
      // }

      console.log(captchaResponse?.data?.response?.data?.success )
      if (captchaResponse?.data?.response?.data?.success !== true) {
        setErrorMessage("reCAPTCHA verification failed. Please try again.");
        console.error("reCAPTCHA verification failed:", captchaResponse?.data);
        return;
      }
    
      console.log("reCAPTCHA verification successful:", captchaResponse?.data);
    
      const payload = {
        email_id: email.trim(),
        password: password.trim(),
      };

      const url = `${process.env.REACT_APP_NEW_API_URL}/login`;
      const response = await axios.post(url, payload);
      console.log("login successfully! ", response?.data);
      if (response?.status === 200) {
        const roleId = response?.data?.result?.user?.role_id;
        const userData = response?.data?.result?.user;
        setUsers(userData);
        if (roleId === 1) {
          navigate(`/admin`);
        } else if (roleId === 2) {
          navigate(`/profile/doctor`);
        } else {
          navigate(`/profile/patient`);
        }
      }
    } catch (error) {
      console.log("Error while login: ", error);
      setErrorMessage(error?.response?.data?.result?.message);
    }
  };

  return (
    <section className="bg-[#F9F8F8] min-h-screen flex items-center justify-center px-4 py-8 sm:px-6 lg:px-8 font-poppins">
      <div className="w-full max-w-md space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6 sm:p-8">
          <img
            className="w-[100px] h-auto mx-auto"
            src={cognismilesLogo}
            alt="logo"
          />
          <h1 className="text-xl font-medium text-gray-800 text-center mb-6">
            Sign in to your account
          </h1>
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm lg:text-lg font-medium text-gray-700 mb-1"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                placeholder="johnDoe@example.com"
                value={email}
                onChange={(e) => {
                  setErrorMessage("");
                  setEmail(e.target.value);
                }}
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm lg:text-lg font-medium text-gray-700 mb-1"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:border-black"
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  {showPassword ? (
                    <svg
                      className="w-6 h-6 text-gray-400 cursor-pointer"
                      aria-label="Hide password"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeWidth="2"
                        d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                      />
                      <path
                        stroke="currentColor"
                        strokeWidth="2"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-400 cursor-pointer"
                      aria-label="Show password"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            {errorMessage && (
              <p className="text-red-500 text-sm">{errorMessage}</p>
            )}
            <div className="flex items-center justify-between">
              <button
                type="button"
                className="text-sm font-medium text-primary-600 hover:underline"
              >
                Forgot password?
              </button>
            </div>

            <button
              type="submit"
              className="w-full  px-8 py-2.5 text-white bg-[#4838C8] focus:ring-4 focus:outline-none focus:ring-[#4838C8] font-medium rounded-lg text-sm text-center"
            >
              Login
            </button>
          </form>
          <p className="mt-4 text-center text-sm text-gray-600">
            Don't have an account yet?{" "}
            <button
              onClick={() => navigate("/register")}
              className="font-medium text-[#4838C8] hover:underline"
            >
              Sign up
            </button>
          </p>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
